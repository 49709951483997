<template>
  <div id="parcerias" class="baloo_paaji_regular" >
    <br>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 data-aos="fade-down" style="color: gray"><b>Conheça alguns clientes</b></h2>
        </v-col>
        <v-col cols="12">
          <v-slide-grupo :lista="lista2"></v-slide-grupo>
        </v-col>

      </v-row>
    </v-container>

  </div>
</template>

<script async>
/* eslint-disable */
  import logo_1 from '../../assets/img/icones/empresas/logos (1).webp'
  import logo_2 from '../../assets/img/icones/empresas/gergilin.png'
  import logo_3 from '../../assets/img/icones/empresas/logos (6).png'
  import logo_4 from '../../assets/img/icones/empresas/logos (7).png'
  import logo_5 from '../../assets/img/icones/empresas/boca_fresca.png'

  export default {
    name: 'SecaoOndeEstamos',
    components: {
      VSlideGrupo: () => import('../../components/VSlideGrupo.vue'),
    },
    data: () => ({
      lista2: [

        {link:'/', src:logo_2},
        {link:'/', src:logo_3},
        {link:'/', src:logo_5},
        {link:'/', src:logo_4},
        {link:'/', src:logo_1},
      ]
    }),
    methods: {},
  }
</script>
